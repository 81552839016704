<template>
  <v-navigation-drawer
    v-if="data"
    :key="`${data.id}:${data.name}`"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 362"
    app
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div class="text-h6 py-1">
        <v-icon left>
          fal fa-fw fa-diagnoses
        </v-icon>
        {{ $t('Update') }} {{ data.category_code }}.{{ data.diagnosis_code }}
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        right
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-container class="spy-0">
      <v-row class="pa-3">
        <v-col
          cols="12"
          class="mt-2 pb-0"
        >
          <v-textarea
            v-model="data.description"
            label="Description"
            max-length="255"
            rows="3"
            dense
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-textarea
            v-model="data.abbreviated"
            label="Abbreviated"
            max-length="255"
            rows="2"
            dense
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-checkbox
            v-model="data.indicates_pregnancy"
            prepend-icon="fas fa-fw fa-person-pregnant"
            label="Indicates Pregnancy"
            color="pink darken-1"
            dense
          />
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="mt-3 pa-3">
        <v-col
          cols="12"
        >
          <v-autocomplete
            v-model="data.group_id"
            :items="groups"
            label="Group"
            :loading="loading"
            item-text="name"
            item-value="id"
            dense
          />
        </v-col>
        <v-col
          v-if="data.group_id && data.group"
          cols="12"
        >
          <source-select
            v-model="data.group.relevant_sources"
            label="Relevant Sources"
            dense
          />
        </v-col>
      </v-row>
    </v-container>

    <template
      #append
    >
      <v-divider />
      <v-col>
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateDiagnosis"
        >
          {{ $t('Update') }} Diagnosis Code
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  export default {
    components: {
      SourceSelect: () => import("@/components/source/SourceSelect.vue"),
    },
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        groups: [],
        loadingGroup: false,
        loading: false,
      }
    },
    mounted () {
      this.fetchGroups();
    },
    methods: {
      fetchGroups () {
        this.loadingGroup = true;
        this.axios.get('admin/diagnoses/groups')
        .then((response) => {
          this.groups = response.data
        }).catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loadingGroup = false
        })
      },
      updateDiagnosis () {
        this.loading = true

        this.axios.request({
          method: 'patch',
          url: `admin/diagnoses/${this.data.icd_10_code}`,
          data: this.data,
        })
        .then(() => {
          this.$emit('close-drawer')
          this.$toast.success('Diagnosis Code updated')
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
